import { IDetails } from "@src/components/molecules/DRepMetadata";
import { ToastId } from "@src/constants/toastId";
import {
  IDRepFormField,
  IMetadata,
  MetadataBody,
  metadataFieldType,
  Reference,
  ReferenceWithId,
} from "@src/models/dtos/metadata";
import { blake2bHex } from "blakejs";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

export function calculateFileHashFromValue(data: any) {
  try {
    const stringifyData = JSON.stringify(data);
    const buffer = Buffer.from(stringifyData, "utf8");
    return blake2bHex(buffer, undefined, 32);
  } catch (error) {
    console.error("Error converting file", error);
  }
}

export async function getContentFromFile(file: File): Promise<any | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const content = e.target?.result as string;
      try {
        resolve(content); // Resolve the Promise with parsed JSON
      } catch (error) {
        toast.error(
          "Failed to parse the file. Make sure the file format is valid JSON.",
          { toastId: ToastId.ERROR_TOAST },
        );
        reject(error); // Reject the Promise if parsing fails
      }
    };

    reader.onerror = () => {
      toast.error("Failed to read the file.", { toastId: ToastId.ERROR_TOAST });
      reject(new Error("File reading error"));
    };

    reader.readAsText(file);
  });
}

// Validate the structure of the data
export const isValidateMetadata = (data: any): data is IMetadata => {
  try {
    if (typeof data !== "object" || data === null) return false;

    const contextValid = typeof data["@context"] === "object"; // Adjust according to actual context type
    const authorsValid =
      Array.isArray(data.authors) &&
      data.authors.every((a: any) => typeof a === "string");
    const hashAlgorithmValid = typeof data.hashAlgorithm === "string";
    const bodyValid = typeof data.body === "object" && data.body !== null; // Add more validation for MetadataBody

    return contextValid && authorsValid && hashAlgorithmValid && bodyValid;
  } catch (e) {
    console.log(e, "error");
    return false;
  }
};

export const isValidJsonFormat = (value: any) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const generateMetadata = (formData: MetadataBody): IMetadata => ({
  "@context": {
    "@language": "en-us",
    CIP100:
      "https://github.com/cardano-foundation/CIPs/blob/master/CIP-0100/README.md#",
    CIP119:
      "https://github.com/cardano-foundation/CIPs/blob/master/CIP-0119/README.md#",
    hashAlgorithm: "CIP100:hashAlgorithm",
    body: {
      "@id": "CIP119:body",
      "@context": {
        references: {
          "@id": "CIP119:references",
          "@container": "@set",
          "@context": {
            GovernanceMetadata: "CIP100:GovernanceMetadataReference",
            Identity: "CIP119:IdentityReference",
            Link: "CIP119:LinkReference",
            Other: "CIP100:OtherReference",
            label: "CIP100:reference-label",
            uri: "CIP100:reference-uri",
            referenceHash: {
              "@id": "CIP119:referenceHash",
              "@context": {
                hashDigest: "CIP119:hashDigest",
                hashAlgorithm: "CIP100:hashAlgorithm",
              },
            },
          },
        },
        paymentAddress: "CIP119:paymentAddress",
        givenName: "CIP119:givenName",
        image: "CIP119:image",
        objectives: "CIP119:objectives",
        motivations: "CIP119:motivations",
        qualifications: "CIP119:qualifications",
        doNotList: "CIP119:doNotList",
      },
    },
    authors: {
      "@id": "CIP100:authors",
      "@container": "@set",
      "@context": {
        name: "http://xmlns.com/foaf/0.1/name",
        witness: {
          "@id": "CIP100:witness",
          "@context": {
            witnessAlgorithm: "CIP100:witnessAlgorithm",
            publicKey: "CIP100:publicKey",
            signature: "CIP100:signature",
          },
        },
      },
    },
  },
  authors: [],
  hashAlgorithm: {
    "@value": "blake2b-256",
  },
  body: {
    doNotList: false,
    givenName: {
      "@value": formData.givenName.toString(),
    },
    motivations: { "@value": formData.motivations?.toString() || "" },
    objectives: { "@value": formData.objectives?.toString() || "" },
    paymentAddress: { "@value": formData.paymentAddress?.toString() || "" },
    qualifications: { "@value": formData.qualifications?.toString() || "" },
    references: formData.references.map((reference) => {
      return {
        ...reference,
        label: { "@value": reference.label.toString() },
        uri: { "@value": reference.uri.toString() },
      };
    }),
  },
});

export function convertFormFieldToDRepMetadataBody(
  dRepFormField: IDRepFormField,
  givenName: string,
): MetadataBody {
  const { objectives, motivations, paymentAddress, qualifications } =
    dRepFormField;

  return {
    doNotList: false,
    givenName,
    objectives,
    motivations,
    paymentAddress,
    qualifications,
    references: filterAndMapReferences(dRepFormField.references),
  };
}

function filterAndMapReferences(references: Array<ReferenceWithId>) {
  return references
    .filter(({ label, uri }) => !isEmpty(label) && !isEmpty(uri))
    .map(({ "@type": type, label, uri }) => ({
      "@type": type,
      label,
      uri,
    }));
}

export function convertDRepMetadataToFormField(
  metadata: IMetadata,
): IDRepFormField {
  const {
    objectives,
    motivations,
    paymentAddress,
    qualifications,
    references,
  } = metadata.body;

  return {
    objectives: getFieldValue(objectives),
    motivations: getFieldValue(motivations),
    paymentAddress: getFieldValue(paymentAddress),
    qualifications: getFieldValue(qualifications),
    references: mapReferencesWithId(references),
  };
}

function mapReferencesWithId(references: Array<Reference>) {
  return references.map(({ "@type": type, label, uri }) => ({
    "@type": type,
    label: getFieldValue(label),
    uri: getFieldValue(uri),
    id: uuid(),
  }));
}

export function combineLinkWithHttps(link: string): string {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
}

export function getFieldValue(field: string | metadataFieldType | undefined) {
  return typeof field === "string" ? field : (field?.["@value"] ?? "");
}

export function getValueByPath<T>(obj: T, path: string): any {
  return path.split(".").reduce((acc: unknown, part: string) => {
    if (acc && typeof acc === "object" && part in acc) {
      return (acc as Record<string, unknown>)[part];
    }
    return path;
  }, obj);
}

export const getReferenceDescription = (
  typeFilter: string | string[],
  metadata: IMetadata | null,
  defaultLabel: string = "Missing label",
): IDetails[] => {
  if (!metadata) return [];

  const types = Array.isArray(typeFilter) ? typeFilter : [typeFilter];

  return metadata.body.references
    .filter((reference) => types.includes(reference["@type"]))
    .map((reference) => {
      return {
        label: !isEmpty(getFieldValue(reference.label))
          ? getFieldValue(reference.label)
          : defaultLabel,
        className: isEmpty(getFieldValue(reference.label))
          ? "!text-error-600"
          : "",
        value: getFieldValue(reference.uri),
      };
    });
};
